import { apiClient } from "services/api/client";
import {
  IPostingSummary,
  IUploadedAttachment,
  IGetStatementEmailsForPostingResult,
  StatementType,
  IGetPostingFilesResult,
  ICanCreatePostingResult,
} from "services/api/types";

const POSTINGS_URL = "administrator/postings";

export const getCanCreate = async () => {
  const url = `${POSTINGS_URL}/canCreate`;
  const result = await apiClient.get<ICanCreatePostingResult>(url);
  return result.data;
};

export const resendEmail = async (statementEmailId: string) => {
  const url = `administrator/statementEmails/${statementEmailId}/resend`;
  await apiClient.post(url);
};

export const getRecentList = async () => {
  const url = `${POSTINGS_URL}`;
  const result = await apiClient.get<IPostingSummary[]>(url);
  return result.data;
};

export const getPostingFiles = async (postingId: string) => {
  const url = `${POSTINGS_URL}/${postingId}/files`;
  const result = await apiClient.get<IGetPostingFilesResult>(url);
  return result.data;
};

export const enqueueActivitySummaryEmails = async (postingId: string) => {
  const url = `${POSTINGS_URL}/${postingId}/enqueueActivitySummaryEmails`;
  const result = await apiClient.post(url);
  return result.data;
};

export const enqueueSponsorStatementEmails = async (postingId: string) => {
  const url = `${POSTINGS_URL}/${postingId}/enqueueSponsorEmails`;
  const result = await apiClient.post(url);
  return result.data;
};

export const getStatementEmails = async (
  postingId: string,
  statementType: StatementType
) => {
  const url = `${POSTINGS_URL}/${postingId}/emails`;
  const response = await apiClient.get<IGetStatementEmailsForPostingResult>(
    url,
    {
      params: {
        postingId,
        statementType,
      },
    }
  );

  return response.data;
};

export const create = async (
  attachments: IUploadedAttachment[],
  sponsorMessage: string,
  memberMessage: string
) => {
  const url = `${POSTINGS_URL}`;
  const result = await apiClient.post<any>(url, {
    attachments,
    sponsorMessage,
    memberMessage,
  });

  return result.data;
};

export const getDetails = async (postingId: string) => {
  const url = `${POSTINGS_URL}/${postingId}`;
  const result = await apiClient.get<IPostingSummary>(url);
  return result.data;
};
