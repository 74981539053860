import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ITinyEventPayerInvoice } from "services/api/types";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatMoney } from "utils/number";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";
import NoDataMessage from "components/Shell/NoDataMessage";
import Typography from "@material-ui/core/Typography";

type AppliedAmounts = {
  [key: string]: number | undefined;
};

type Props = {
  activeInvoices: ITinyEventPayerInvoice[];
  onInvoiceAmountPaidChanged: (invoiceId: string, amountPaid: number) => void;
  appliedAmounts: AppliedAmounts;
  originalAppliedAmounts: AppliedAmounts;
  isRefund: boolean;
};

const InvoiceUpdater = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      formControl: {
        margin: theme.spacing(1),
        maxWidth: 150,
      },
      newValue: {},
      oldValue: {
        // textDecoration: "line-through",
        display: "none",
      },
      status: {
        display: "flex",
      },
    })
  );

  const classes = useStyles();

  const renderCurrentStatus = (invoice: ITinyEventPayerInvoice) => {
    if (invoice.void) {
      return <Typography variant="body2">Void</Typography>;
    } else if (invoice.amountPaid === invoice.amount) {
      return <Typography variant="body2">Paid</Typography>;
    } else if (invoice.amount - invoice.amountPaid > 0) {
      return <Typography variant="body2">Open</Typography>;
    }

    return <div>Unknown</div>;
  };

  const renderPreviewStatus = (invoice: ITinyEventPayerInvoice) => {
    const appliedToThisInvoice = props.appliedAmounts[invoice.id] ?? 0;
    const newAmountPaid =
      invoice.amountPaid + appliedToThisInvoice * (props.isRefund ? -1 : 1);

    if (invoice.void) {
      return <Typography variant="body2">Void</Typography>;
    } else if (newAmountPaid === invoice.amount) {
      return <Typography variant="body2">Paid</Typography>;
    } else if (invoice.amount - newAmountPaid > 0) {
      return <Typography variant="body2">Open</Typography>;
    }

    return <div>Unknown</div>;
  };

  const renderArrows = () => {
    return <span>&nbsp;&gt;&gt;&nbsp;</span>;
  };

  const renderInvoiceTable = () => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Invoice #</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Due</TableCell>
            <TableCell align="right">Paid</TableCell>
            <TableCell align="right">Amount to Apply</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.activeInvoices.map((invoice) => {
            const appliedToThisInvoice = props.appliedAmounts[invoice.id];
            const originalAppliedAmount =
              props.originalAppliedAmounts[invoice.id] ?? 0;

            const hasNumberApplied: boolean =
              appliedToThisInvoice !== undefined &&
              !isNaN(appliedToThisInvoice);

            return (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>
                  <div className={classes.status}>
                    <span>{renderCurrentStatus(invoice)}</span>
                    {hasNumberApplied && (
                      <>
                        {renderArrows()}
                        <span className="foo">
                          {renderPreviewStatus(invoice)}
                        </span>
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div>
                    <span>
                      {formatMoney(invoice.amount - invoice.amountPaid)}
                    </span>
                    {hasNumberApplied && (
                      <>
                        {renderArrows()}
                        <span>
                          {formatMoney(
                            invoice.amount -
                              invoice.amountPaid -
                              (appliedToThisInvoice ?? 0) *
                                (props.isRefund ? -1 : 1) +
                              originalAppliedAmount
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div>
                    <span>{formatMoney(invoice.amountPaid)}</span>
                    {hasNumberApplied && (
                      <>
                        {renderArrows()}
                        <span>
                          {formatMoney(
                            invoice.amountPaid +
                              (appliedToThisInvoice ?? 0) *
                                (props.isRefund ? -1 : 1) -
                              originalAppliedAmount
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <TextField
                    variant="outlined"
                    margin="dense"
                    value={props.appliedAmounts[invoice.id] ?? ""}
                    onChange={(evt) => {
                      const value = parseFloat(evt.target.value);
                      props.onInvoiceAmountPaidChanged(
                        invoice.id,
                        isNaN(value) ? 0 : value
                      );
                    }}
                    className={classes.formControl}
                    label="Amount"
                    id="amount"
                    // Note: has to be uppercase
                    InputProps={{
                      inputComponent: MaskedNumberInput as any,
                      inputProps: {
                        allowNegative: false,
                        prefix: "$",
                        decimalScale: 2,
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const renderNoInvoicesMessage = () => {
    return <NoDataMessage message="No active invoices found" />;
  };

  return (
    <div className={classes.root}>
      {props.activeInvoices.length === 0
        ? renderNoInvoicesMessage()
        : renderInvoiceTable()}
    </div>
  );
};

export default InvoiceUpdater;
