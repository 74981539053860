import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Observer } from "mobx-react";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import MuiTextField from "@material-ui/core/TextField";
import * as yup from "yup";
import { Formik, Field, Form, FormikActions, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import { IEventAdjustment, IEventPayerAdjustment } from "services/api/types";
import FormHelperText from "@material-ui/core/FormHelperText";
import api from "services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "components/Shell/Loader";
import eventStore from "stores/admin/events";

type Props = {
  handleClose: () => void;
  eventId: string;
  eventPayerId: string;
  eventGroupAdjustment?: IEventPayerAdjustment;
};

export type Option = {
  value: string;
  label: string;
};

type FormData = {
  eventAdjustment: Option | null;
  quantity: number;
};

const AddEditEventPayerAdjustmentModal = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [eventAdjustments, setEventAdjustments] = React.useState<
    IEventAdjustment[]
  >([]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
      },
      form: {
        maxWidth: "600px",
        margin: "0 auto",
        display: "grid",
        gridGap: theme.spacing(1),
        gridTemplateColumns: "3fr 1fr",
      },
      error: {
        color: theme.palette.error.main,
        marginLeft: theme.spacing(2),
      },
    })
  );

  const { eventId } = props;
  React.useEffect(() => {
    const loadEventAdjustments = async () => {
      setIsLoading(true);
      const eventAdjustments = await api.admin.events.getEventAdjustments(
        eventId
      );
      setEventAdjustments(eventAdjustments);
      setIsLoading(false);
    };

    loadEventAdjustments();
  }, [eventId]);

  const classes = useStyles();

  const schema = yup.object().shape({
    eventAdjustment: yup
      .object()
      .nullable()
      .required("Discount/Upgrade is required"),
    quantity: yup.number().min(1).required("Quanity is required"),
  });

  const submit = async (values: FormData, actions: FormikActions<FormData>) => {
    if (props.eventGroupAdjustment) {
      await eventStore.updateEventGroupAdjustment({
        eventAdjustmentId: values.eventAdjustment!.value,
        eventPayerAdjustmentId: props.eventGroupAdjustment.id,
        quantity: values.quantity,
      });
    } else {
      await eventStore.addEventPayerAdjustment({
        eventAdjustmentId: values.eventAdjustment!.value,
        eventPayerId: props.eventPayerId,
        quantity: values.quantity,
      });
    }

    props.handleClose();
  };

  const renderAdjustmentDropdown = (
    formikProps: FormikProps<FormData>,
    options: Option[]
  ) => {
    const error = formikProps.errors.eventAdjustment;

    const showError =
      Boolean(error) && (formikProps.touched.eventAdjustment || false);

    return (
      <div>
        <Autocomplete
          placeholder="Event Discount/Upgrade"
          onBlur={formikProps.handleBlur}
          onChange={(event: any, option: any) => {
            return formikProps.setFieldValue("eventAdjustment", option);
          }}
          options={options}
          value={formikProps.values.eventAdjustment}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label="Discount/Upgrade"
              variant="outlined"
              name="eventType"
              error={showError}
              margin="dense"
            />
          )}
          getOptionLabel={(option) => {
            if (typeof option.label === "string") {
              return option.label;
            }

            return (option as any).label.label;
          }}
        />
        {showError ? (
          <FormHelperText className={classes.error}>{error}</FormHelperText>
        ) : undefined}
      </div>
    );
  };

  if (isLoading) {
    return <Loader message="Loading" />;
  }

  const options = eventAdjustments.map((du) => {
    return { label: `${du.name} (${du.amountDollars})`, value: du.id };
  });

  const getInitialEventAdjustmentOption = () => {
    if (props.eventGroupAdjustment) {
      const { eventAdjustmentId } = props.eventGroupAdjustment;
      const option = options.find((o) => o.value === eventAdjustmentId);
      return option!;
    } else {
      return null;
    }
  };

  return (
    <Observer
      render={() => {
        return (
          <div>
            <Formik
              validationSchema={schema}
              onSubmit={submit}
              initialValues={{
                eventAdjustment: getInitialEventAdjustmentOption(),
                quantity: props.eventGroupAdjustment
                  ? props.eventGroupAdjustment.quantity
                  : 1,
              }}
              render={(formikProps) => {
                return (
                  <ResponsiveModalShell
                    handleSave={formikProps.handleSubmit}
                    disableSaveButton={formikProps.isSubmitting}
                    handleClose={props.handleClose}
                    title="Add Discount/Upgrade"
                  >
                    <Form className={classes.form}>
                      {renderAdjustmentDropdown(formikProps, options)}
                      <Field
                        variant="outlined"
                        margin="dense"
                        component={TextField}
                        label="Quantity"
                        name="quantity"
                        disabled={formikProps.isSubmitting}
                        type="number"
                      />
                    </Form>
                  </ResponsiveModalShell>
                );
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default AddEditEventPayerAdjustmentModal;
