import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDebounce } from "utils/hooks";
import api from "services/api";
import { ICompanySearchResults } from "services/api/types";

type Props = {
  existingValue: CompanyOption | undefined;
  setExistingValue: (option: CompanyOption) => void;
  newValue: string | undefined;
  setNewValue: (value: string) => void;
  currentUserType: "Admin" | "Member";
  hideMessages?: boolean;
  hideCompanyType?: boolean;
  disabled?: boolean;
  label: string;
  includeCompetitors?: boolean;
};

export type CompanyOption = {
  value: string;
  label: string;
};

// This is different than the company select component because
// it's a true combo box. The user can enter a new company
// name and create it.

const CompanyComboBox = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState<CompanyOption[]>([]);
  const [loadPending, setLoadPending] = React.useState(false);

  const debouncedSearchTerm = useDebounce(props.newValue, 500);

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      const searchCompanies = async (searchTerm: string) => {
        setIsLoading(true);

        let searchResult: ICompanySearchResults | undefined;

        if (props.currentUserType === "Admin") {
          searchResult = await api.admin.search.searchCompanies(
            searchTerm,
            true,
            "Competitor",
            "Sponsor",
            "Prospect"
          );
        } else if (props.currentUserType === "Member") {
          searchResult = await api.shared.sponsors.searchSponsors(
            searchTerm,
            props.includeCompetitors ?? false
          );
        }

        if (!searchResult) {
          setIsLoading(false);
          return;
        }

        if (searchResult.searchTerm === debouncedSearchTerm) {
          const options = searchResult.companies.map((company) => {
            const label = props.hideCompanyType
              ? company.name
              : `${company.name} (${company.companyType})`;

            return {
              value: company.id,
              label,
            };
          });

          setOptions(options);
          setIsLoading(false);
          setLoadPending(false);
        }
      };

      searchCompanies(debouncedSearchTerm);
    }
    // Not sure what to do with the dependencies array. It's missing
    // props because we auto select if there's only one result.
    // Adding props causes an infitie loop.
  }, [
    debouncedSearchTerm,
    props.currentUserType,
    props.hideCompanyType,
    props.includeCompetitors,
  ]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      autoComplete: {
        // minWidth: "30rem",
      },
      note: {
        fontSize: "0.9rem",
        color: theme.palette.grey[500],
      },
      noRecords: {
        fontSize: "0.9rem",
        color: theme.palette.grey[800],
      },
      whiteBackground: {
        background: "white",
      },
    })
  );

  const handleInputChange = (evt: React.ChangeEvent<any>) => {
    // NOTE: using any here because Autocomplete uses {}
    // which isn't allowed here

    // Setting the inital value of the dropdown triggers this event
    // with a null evt
    if (!evt) {
      return;
    }

    setOptions([]);
    const searchTerm = (evt.currentTarget as any).value || "";
    props.setNewValue(searchTerm);
    setLoadPending(true);
  };

  const classes = useStyles();

  const shouldShowNoResultsMessage =
    debouncedSearchTerm === props.newValue &&
    debouncedSearchTerm.length > 0 &&
    !isLoading &&
    !loadPending &&
    options.length === 0;

  return (
    <div>
      <Autocomplete
        disabled={props.disabled}
        noOptionsText={isLoading ? "Searching..." : "No companies found"}
        className={clsx(classes.autoComplete)}
        options={options}
        loading={isLoading}
        // Note freeSolo prevents loadingText and noOptionsText
        // from being displayed for some reason, but we need it
        // for this design
        freeSolo
        filterOptions={(options, state) => {
          return options;
        }}
        loadingText="Searching companies..."
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => {
          if (typeof option.label === "string") {
            return option.label;
          }

          if (typeof option === "string") {
            return option;
          }

          return "unknown";
        }}
        onChange={(event: any, option: any) => {
          if (option && typeof option === "object") {
            props.setExistingValue(option);
          } else if (typeof option === "string") {
            props.setNewValue(option);
          }
        }}
        onInputChange={handleInputChange}
        // THIS WAS IMPORTANT...if there's no existing value, still pass and Option
        // not just a string
        value={props.existingValue ?? { label: props.newValue, value: "" }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={props.label}
              value={props.newValue}
              placeholder="Search by ID or business name"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              margin="dense"
            />
          );
        }}
      />
      {shouldShowNoResultsMessage && !props.hideMessages ? (
        <div className={classes.noRecords}>No suppliers found</div>
      ) : undefined}
      {!props.hideMessages && (
        <div className={classes.note}>
          If company is not found, a new Competitor will be created with this
          Category
        </div>
      )}
    </div>
  );
};

export default CompanyComboBox;
