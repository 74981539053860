import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { useDebounce } from "utils/hooks";
import api from "services/api";
import { CompanyType } from "services/api/types";
import { CompanyOption } from "types/options";
import { companyColors } from "areas/Shared/companyColors";

type Props = {
  selectedOption: CompanyOption | undefined;
  onSelectedOptionChanged: (option: CompanyOption | undefined) => void;
  // Changed this to a ref object because it's in the dependency
  // array. Can't have components keep passing in a new array
  // every time.
  companyTypes?: React.MutableRefObject<CompanyType[]>;
  label?: string;
  minWidth?: string;
  // Determines the API endpoint to use when searching
  userType: "Admin" | "Sponsor" | "Member";
  disabled?: boolean;
  hiddenOptionIds?: string[];
  searchInactives?: boolean;
};

const CompanyDropdown = (props: Props) => {
  const [hasSearched, setHasSearched] = React.useState(false);
  const [options, setOptions] = React.useState<CompanyOption[]>(
    props.selectedOption ? [props.selectedOption] : []
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [textBoxValue, setTextBoxValue] = React.useState("");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      autoComplete: {
        minWidth: props.minWidth ?? "30rem",
      },
      companyTypes: {
        display: "inline-block",
        marginLeft: theme.spacing(1),
        fontSize: "smaller",
        color: "white",
        padding: theme.spacing(0, 1),
        borderRadius: "10px",
      },
      sponsor: {
        backgroundColor: companyColors.sponsor,
      },
      competitor: {
        backgroundColor: companyColors.competitor,
      },
      prospect: {
        backgroundColor: companyColors.prospect,
      },
      member: {
        backgroundColor: companyColors.member,
      },
    })
  );

  const handleInputChange = (evt: React.ChangeEvent<any>) => {
    // Setting the inital value of the dropdown triggers this event
    // with a null evt
    if (!evt) return;

    const searchTerm = (evt.currentTarget as any).value || "";
    setTextBoxValue(searchTerm);
    setHasSearched(false);
  };

  const debouncedSearchTerm = useDebounce(textBoxValue, 500);

  React.useEffect(() => {
    const getSearchMethodToCall = () => {
      if (props.userType === "Admin") {
        const companyTypes = props.companyTypes
          ? props.companyTypes.current
          : [];
        const activeOnly = props.searchInactives ? false : true;
        return (q) =>
          api.admin.search.searchCompanies(q, activeOnly, ...companyTypes);
      } else if (props.userType === "Sponsor") {
        return (q) => api.sponsors.purchasers.search(q);
      } else if (props.userType === "Member") {
        return (q) => api.shared.sponsors.searchSponsors(q);
      } else {
        throw Error("Invalid property types");
      }
    };

    const searchCompanies = async (searchTerm: string) => {
      setIsLoading(true);

      const searchMethodToCall = getSearchMethodToCall();

      const searchResult = await searchMethodToCall(searchTerm);

      if (searchResult.searchTerm === debouncedSearchTerm) {
        let options = searchResult.companies.map<CompanyOption>((r) => {
          return {
            value: r.id,
            companyType: r.companyType,
            label: r.isActive
              ? `${r.name} (${r.identifier})`
              : `${r.name} (${r.identifier}) - Inactive`,
          };
        });

        if (props.hiddenOptionIds) {
          options = options.filter(
            (o) => !props.hiddenOptionIds!.includes(o.value)
          );
        }

        setOptions(options);
        setIsLoading(false);
        setHasSearched(true);
      }
    };

    if (debouncedSearchTerm) {
      searchCompanies(debouncedSearchTerm);
    }
  }, [
    debouncedSearchTerm,
    props.userType,
    props.companyTypes,
    props.hiddenOptionIds,
    props.searchInactives,
  ]);

  const renderCompanyDropdown = () => {
    return (
      <Autocomplete
        noOptionsText={
          hasSearched ? "No companies found" : "Enter text to search companies"
        }
        placeholder="Search companies"
        className={clsx(classes.autoComplete)}
        options={options}
        disabled={props.disabled}
        loading={isLoading}
        filterOptions={(options, state) => {
          return options;
        }}
        loadingText="Searching companies..."
        getOptionSelected={(option, value) => {
          if (!option || !value) return false;
          return option.value === value.value;
        }}
        getOptionLabel={(option) => {
          if (typeof option.label === "string") {
            return option.label;
          }

          return (option as any).label.label;
        }}
        onChange={(event: any, option: any) => {
          return props.onSelectedOptionChanged(option as CompanyOption);
        }}
        onInputChange={handleInputChange}
        renderOption={(opt) => {
          return opt.companyType &&
            props.companyTypes &&
            props.companyTypes.current.length > 1 ? (
            <div>
              <span>{opt.label}</span>
              <span
                className={clsx(classes.companyTypes, {
                  [classes.competitor]:
                    (opt.companyType as CompanyType) === "Competitor",
                  [classes.sponsor]:
                    (opt.companyType as CompanyType) === "Sponsor",
                  [classes.member]:
                    (opt.companyType as CompanyType) === "Member",
                  [classes.prospect]:
                    (opt.companyType as CompanyType) === "Prospect",
                })}
              >
                {opt.companyType}
              </span>
            </div>
          ) : (
            <div>{opt.label}</div>
          );
        }}
        value={props.selectedOption}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={props.label ? props.label : "Select Company"}
            style={{ margin: 0 }}
            InputProps={{
              ...params.InputProps,
              style: {
                color: "unset",
                fontWeight: "unset",
              },
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            margin="dense"
          />
        )}
      />
    );
  };

  const classes = useStyles();

  return <div className={classes.root}>{renderCompanyDropdown()}</div>;
};

export default CompanyDropdown;
