import { apiClient } from "services/api/client";
import {
  IContactDetailed,
  IAccountInfo,
  IStatementPreview,
  IMarketingFundEvents,
} from "services/api/types";

const ACCOUNTS_URL = "administrator/accounts";

export const getContactsForAccount = async (accountId: string) => {
  const url = `${ACCOUNTS_URL}/${accountId}/contacts`;
  const response = await apiClient.get<IContactDetailed[]>(url);
  return response.data;
};

export const getMarketingFundAccountInfo = async () => {
  const response = await apiClient.get<IAccountInfo>(
    `administrator/marketingFund`
  );
  return response.data;
};

export const getMarketingFundEvents = async () => {
  const response = await apiClient.get<IMarketingFundEvents>(
    `administrator/marketingFund/events`
  );
  return response.data;
};

export const getInfo = async (accountId: string) => {
  const response = await apiClient.get<IAccountInfo>(
    `${ACCOUNTS_URL}/${accountId}`
  );
  return response.data;
};

export const previewCurrentStatement = async (accountId: string) => {
  const response = await apiClient.get<IStatementPreview>(
    `${ACCOUNTS_URL}/${accountId}/statementPreview`
  );

  return response.data;
};
