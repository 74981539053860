import { apiClient } from "services/api/client";

const listsUrl = "administrator/lists";

export const getList = async (name: string) => {
  const response = await apiClient.get<{ id: any }[]>(listsUrl, {
    params: {
      name,
    },
  });

  return response;
};

export const updateListItem = async (command: {
  listName: string;
  data: { id: any };
}) => {
  const url = `${listsUrl}/${command.listName}/${command.data.id}`;
  const response = await apiClient.put<{ id: any }>(url, command);

  return response;
};

export const createListItem = async (command: {
  listName: string;
  data: any;
}) => {
  const url = `${listsUrl}/${command.listName}`;
  const response = await apiClient.post<{ id: any }>(url, command);

  return response;
};

export const deleteListITem = async (command: {
  listName: string;
  id: any;
}) => {
  const url = `${listsUrl}/${command.listName}/${command.id}`;
  const response = await apiClient.delete(url);

  return response;
};
