import { apiClient } from "services/api/client";
import {
  IFullOrderModel,
  OrderFilterType,
  AddressAction,
  IOrdersThatNeedAttentionCounts,
  IGetOrdersResult,
} from "services/api/types";

const ADMIN_ORDERS_URL = "administrator/orders";
const ADMIN_CATALOG_ORDERS_URL = "administrator/catalogOrders";

export const get = async (
  filterType: OrderFilterType,
  companySearch?: string,
  orderId?: string
) => {
  const response = await apiClient.get<IGetOrdersResult>(ADMIN_ORDERS_URL, {
    params: {
      filterType,
      companySearch,
      orderId,
    },
  });
  return response;
};

export const cancel = async (orderId: string, addressAction: AddressAction) => {
  const response = await apiClient.post<IFullOrderModel>(
    `administrator/cancelledOrders/${orderId}`,
    {
      addressAction,
      orderId,
    }
  );
  return response;
};

export const approve = async (catalogOrderId: string) => {
  const response = await apiClient.post<IFullOrderModel>(
    `administrator/approvedOrders/${catalogOrderId}`
  );
  return response.data;
};

export const retry = async (orderId: string) => {
  const response = await apiClient.post<IFullOrderModel>(
    `administrator/retriedOrders/${orderId}`
  );
  return response.data;
};

export const getNeedAttentionCount = async () => {
  const response = await apiClient.get<IOrdersThatNeedAttentionCounts>(
    `${ADMIN_ORDERS_URL}/needAttentionCount`
  );
  return response;
};

export const scrubOrder = async (catalogOrderId: string) => {
  const url = `${ADMIN_CATALOG_ORDERS_URL}/${catalogOrderId}/scrub`;
  const response = await apiClient.post<IFullOrderModel>(url);
  return response;
};

export const resendConfirmationEmail = async (orderIdNotAGuid: string) => {
  const url = `administrator/orderConfirmationEmails/resend`;
  const response = await apiClient.post(url, {
    orderId: orderIdNotAGuid,
  });

  return response;
};
