import React from "react";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import myStore from "stores/my";
import uiStore from "stores/ui";
import cartStore from "stores/cart";
import CartIcon from "../Orders/components/CartIcon";
import { ApplicationPaths } from "components/api-authorization/ApiAuthorizationConstants";
import { useHistory } from "react-router";
import { paths } from "routes/Paths";
import CompanySelector from "components/Shell/CompanySelector";
import { ICompanyPermissionViewModel } from "services/api/types";
import BcnToolbarLogo from "components/Shell/BcnToolbarLogo";
import BuildPerksToolbarLogo from "components/Shell/BuildPerksToolbarLogo";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { Observer } from "mobx-react";
import * as appConstants from "applications/appConstants";

type Props = {
  width: Breakpoint;
};

const MemberToolbarItems = (props: Props) => {
  const history = useHistory();
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const isMenuOpen = Boolean(menuAnchor);
  const ref = React.useRef();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      grow: {
        flexGrow: 1,
      },
      emailAddress: {
        display: "none",
        [theme.breakpoints.up("lg")]: {
          display: "block",
        },
      },
      pointBalance: {
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      mobileTitlePortal: {
        pointerEvents: "none",
        width: "100%",
        textAlign: "center",
        fontSize: "large",
        position: "absolute",
        left: 0,
        // Many components stick stuff into our portal. This code
        // ensures that only the first one is shown.
        "& div": {
          display: "none",
        },
        "& div:first-child": {
          display: "block",
        },
      },
    })
  );

  const classes = useStyles();

  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMenuAnchor(event.currentTarget as any);
  }

  function handleMenuClose() {
    setMenuAnchor(null);
  }

  function handleChangePassword() {
    handleMenuClose();
    uiStore.showModal({
      type: "ChangePasswordModal",
      persisted: true,
      props: {},
    });
  }

  const handleSignOut = () => {
    const logoutPath = {
      pathname: `${ApplicationPaths.LogOut}`,
      state: { local: true },
    };
    handleMenuClose();
    history.push(logoutPath);
  };

  const handleStopImpersonating = () => {
    myStore.stopImpersonating();
    history.push(myStore.preImpersonationPath ?? paths.app.admin.users);
    setMenuAnchor(null);
  };

  const menuId = "primary-search-account-menu";
  const menu = (
    <Menu
      anchorEl={menuAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      ref={ref}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangePassword}>Change My Password</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
      {myStore.impersonatingUserInfo && (
        <MenuItem onClick={handleStopImpersonating}>
          Stop Impersonating
        </MenuItem>
      )}
    </Menu>
  );

  const handleCompanyChange = (company: ICompanyPermissionViewModel) => {
    myStore.setCurrentCompany(company.companyId);
    if (company.companyType === "Sponsor") {
      history.push(`${paths.app.root}`);
    } else if (company.companyType === "Member") {
      history.push(`${paths.app.root}`);
    }
  };

  const renderCompanySelect = () => {
    if (
      myStore.currentUserInfo &&
      myStore.currentCompany &&
      myStore.currentUserInfo.companies.length > 1
    ) {
      return (
        <CompanySelector
          onChange={handleCompanyChange}
          currentCompanyId={myStore.currentCompany.companyId}
          companyPermissions={myStore.currentUserInfo.companies}
        />
      );
    }

    return undefined;
  };

  return (
    <Observer
      render={() => {
        const { shoppingCartItems } = cartStore;

        return (
          <>
            {process.env.REACT_APP_BRANDING === appConstants.BuildPerks && (
              <BuildPerksToolbarLogo />
            )}
            {process.env.REACT_APP_BRANDING ===
              appConstants.BuildersClubNorth && <BcnToolbarLogo />}
            <div
              className={classes.mobileTitlePortal}
              id="mobile-title-portal"
            />
            {renderCompanySelect()}
            <div className={classes.grow} />
            <Typography className={classes.emailAddress} variant="body1" noWrap>
              {myStore.emailAddress}
            </Typography>
            <IconButton
              edge="end"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <CartIcon
              totalPoints={cartStore.cartPoints}
              removeItem={cartStore.removeFromShoppingCart}
              cartItems={shoppingCartItems}
              badgeCount={cartStore.cartQuantities}
              availablePoints={myStore.currentCompanyPointBalance!}
            />
            {menu}
          </>
        );
      }}
    />
  );
};

export default withWidth()(MemberToolbarItems);
