import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EventsIcon from "@material-ui/icons/Event";
import HomeIcon from "@material-ui/icons/Home";
import MyCompanyIcon from "@material-ui/icons/Stars";
import SponsorsIcon from "@material-ui/icons/Business";
import ContactUsIcon from "@material-ui/icons/ContactPhone";
import ShopRedeemIcon from "@material-ui/icons/MonetizationOn";
import ProductsIcon from "@material-ui/icons/Store";
import AirfareIcon from "@material-ui/icons/Flight";
import HotelIcon from "@material-ui/icons/Hotel";
import GiftCardIcon from "@material-ui/icons/Redeem";
import BarChartIcon from "@material-ui/icons/BarChart";
import Collapse from "@material-ui/core/Collapse";
import myStore from "stores/my";
import * as roles from "types/roles";
import { paths } from "routes/Paths";
import { useHistory } from "react-router";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import clsx from "clsx";
import MobileOnlyHomeListItem from "components/Shell/MobileOnlyHomeListItem";
import NewspaperIcon from "@material-ui/icons/Receipt";
import HelpIcon from "@material-ui/icons/HelpRounded";

const MemberSidebar = () => {
  const history = useHistory();
  const [shopRedeemIsOpen, setShopRedeemIsOpen] = React.useState(true);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      nestedItem: {
        paddingLeft: theme.spacing(6),
      },
      drawerIcon: {
        color: theme.palette.common.white,
      },
    })
  );

  const renderListItem = (
    nested: boolean,
    subRoute: string,
    label: string,
    query: string,
    icon: JSX.Element,
    ...permissionsParam: roles.CompanyRoleKey[]
  ) => {
    if (myStore.hasAnyCompanyRole(permissionsParam)) {
      const route = query
        ? `${paths.app.members.root}/${subRoute}?${query}`
        : `${paths.app.members.root}/${subRoute}`;
      return (
        <ListItem
          className={clsx("bcn-closes-drawer", {
            [classes.nestedItem]: nested,
          })}
          selected={history.location.pathname.endsWith(route)}
          button
          onClick={() => history.push(route)}
        >
          <ListItemIcon className="bcn-closes-drawer">{icon}</ListItemIcon>
          <div className="bcn-closes-drawer">{label}</div>
        </ListItem>
      );
    }

    return undefined;
  };

  const renderCollapseController = () => {
    return (
      <ListItem button onClick={() => setShopRedeemIsOpen(!shopRedeemIsOpen)}>
        <ListItemIcon>
          <ShopRedeemIcon className={classes.drawerIcon} />
        </ListItemIcon>
        <div>Shop/Redeem</div>
        {shopRedeemIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
    );
  };

  const renderCollapse = () => {
    return (
      <Collapse in={shopRedeemIsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/* Mobile Catalog WIP */}
          <ListItem
            className={clsx(classes.nestedItem, "bcn-closes-drawer")}
            selected={history.location.pathname.includes("catalog")}
            button
            onClick={() =>
              history.push(paths.app.adminsAndMembers.mobileCatalog)
            }
          >
            <ListItemIcon className="bcn-closes-drawer">
              <ProductsIcon
                className={clsx(classes.drawerIcon, "bcn-closes-drawer")}
              />
            </ListItemIcon>
            <div className="bcn-closes-drawer">Catalog</div>
          </ListItem>
          {renderListItem(
            true,
            "merchandise-info",
            "Merchandise Info",
            "",
            <ListItemIcon className="bcn-closes-drawer">
              <HelpIcon
                className={clsx(classes.drawerIcon, "bcn-closes-drawer")}
              />
            </ListItemIcon>,
            roles.memberAdmin,
            roles.memberUser
          )}
          {renderListItem(
            true,
            "airfare",
            "Airfare",
            "",
            <AirfareIcon className={classes.drawerIcon} />,
            roles.memberAdmin,
            roles.memberUser
          )}
          {renderListItem(
            true,
            "hotel",
            "Hotel",
            "",
            <HotelIcon className={classes.drawerIcon} />,
            roles.memberAdmin,
            roles.memberUser
          )}
          {renderListItem(
            true,
            "gift-card-orders/create",
            "Cabela’s Gift Cards",
            "",
            <GiftCardIcon className={classes.drawerIcon} />,
            roles.memberAdmin,
            roles.memberUser
          )}
        </List>
      </Collapse>
    );
  };

  const renderList = () => {
    return (
      <List>
        {renderListItem(
          false,
          "",
          "Home",
          "",
          <HomeIcon className={classes.drawerIcon} />,
          roles.memberAdmin,
          roles.memberUser
        )}
        {renderListItem(
          false,
          "events",
          "Events/Trips",
          "",
          <EventsIcon className={classes.drawerIcon} />,
          roles.memberAdmin,
          roles.memberUser
        )}
        {renderCollapseController()}
        {renderCollapse()}
        {renderListItem(
          false,
          "myCompany/overview",
          "My Company",
          "",
          <MyCompanyIcon className={classes.drawerIcon} />,
          roles.memberAdmin
        )}
        {renderListItem(
          false,
          "points/history",
          "Points Earned History",
          "",
          <BarChartIcon className={classes.drawerIcon} />,
          roles.memberAdmin
        )}
        {renderListItem(
          false,
          "sponsors",
          "Sponsors",
          "",
          <SponsorsIcon className={classes.drawerIcon} />,
          roles.memberAdmin,
          roles.memberUser
        )}
        {renderListItem(
          false,
          "newspapers",
          "Newspapers",
          "",
          <NewspaperIcon className={classes.drawerIcon} />,
          roles.memberAdmin,
          roles.memberUser
        )}
        {renderListItem(
          false,
          "contact",
          "Contact Us",
          "",
          <ContactUsIcon className={classes.drawerIcon} />,
          roles.memberAdmin,
          roles.memberUser
        )}
        <MobileOnlyHomeListItem />
      </List>
    );
  };

  const classes = useStyles();
  return renderList();
};

export default MemberSidebar;
