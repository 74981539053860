import { action, observable, computed } from "mobx";
import myStore from "./my";
import api from "services/api";

import {
  ICatalogProduct,
  ICatalogOption,
  ICreateOrderCommand,
  AddressType,
  IOrderItem,
  IShoppingCartItem,
  CreateOrderResult,
} from "services/api/types";

export type LegacyLocalStorageFormat = {
  shoppingCartItems: IShoppingCartItem[];
};

export class CartStore {
  @observable
  public shoppingCartItems: IShoppingCartItem[] = [];

  @observable
  public addressType: AddressType = "existing";

  @observable
  public shipToAddressId: string | undefined;

  @observable
  public showShipToAddressError = false;

  @observable
  public isCreatingOrder = false;

  @observable
  public firstName = "";

  @observable
  public showFirstNameError = false;

  @observable
  public lastName = "";

  @observable
  public showLastNameError = false;

  @observable
  public address1 = "";

  @observable
  public showAddress1Error = false;

  @observable
  public address2 = "";

  @observable
  public city = "";

  @observable
  public showCityError = false;

  @observable
  public state = "";

  @observable
  public showStateError = false;

  @observable
  public zip = "";

  @observable
  public showZipError = false;

  @observable
  public phoneNumber = "";

  @observable
  public phoneNumberError = "";

  @observable
  public deletedProductIds: string[] = [];

  @observable
  public deletedOptionIds: string[] = [];

  @observable
  public isCheckingForOutOfStockItems = false;

  @action.bound
  public setPhoneNumber(value: string) {
    this.phoneNumber = value;
    if (value) {
      this.phoneNumberError = "";
    }
  }

  @action.bound
  public setAddressType(value: AddressType) {
    this.addressType = value;
    if (value === "new") {
      this.shipToAddressId = undefined;
    }
  }

  @action.bound
  public setShipToAddressId(value: string) {
    this.shipToAddressId = value;
    if (this.showShipToAddressError) {
      this.showShipToAddressError =
        this.addressType === "existing" && this.shipToAddressId === undefined;
    }
  }

  @action.bound
  public setFirstName(value: string) {
    this.firstName = value;
    if (this.showFirstNameError)
      this.showFirstNameError = this.firstName.length === 0;
  }

  @action.bound
  public setLastName(value: string) {
    this.lastName = value;
    if (this.showLastNameError)
      this.showLastNameError = this.lastName.length === 0;
  }

  @action.bound
  public setAddress1(value: string) {
    this.address1 = value;
    if (this.showAddress1Error)
      this.showAddress1Error = this.address1.length === 0;
  }

  @action.bound
  public setAddress2(value: string) {
    this.address2 = value;
  }

  @action.bound
  public setCity(value: string) {
    this.city = value;
    if (this.showCityError) this.showCityError = this.city.length === 0;
  }

  @action.bound
  public setState(value: string) {
    this.state = value;
    if (this.showStateError) this.showStateError = this.state.length === 0;
  }

  @action.bound
  public setZip(value: string) {
    this.zip = value;
    if (this.showZipError) this.showZipError = this.zip.length === 0;
  }

  @action.bound
  public async removeFromShoppingCart(productId: string) {
    const itemsToRemove = this.shoppingCartItems.filter(
      (i) => i.product.id === productId
    );

    itemsToRemove.forEach((item) => {
      if (item.option) {
        this.deletedOptionIds = this.deletedOptionIds.filter(
          (id) => id !== item.option!.id
        );
      }

      this.deletedProductIds = this.deletedProductIds.filter(
        (id) => id !== item.product.id
      );
    });

    this.shoppingCartItems = this.shoppingCartItems.filter(
      (i) => i.product.id !== productId
    );

    await this.saveCartItems();
  }

  @computed
  get cartQuantities() {
    return this.shoppingCartItems.reduce((a, b) => a + b.quantity, 0);
  }

  @computed
  get cartPoints() {
    return this.shoppingCartItems.reduce(
      (a, b) => a + b.quantity * b.product.points,
      0
    );
  }

  @action.bound
  public async updateQuantity(productId: string, quantity: number) {
    const cartItem = this.shoppingCartItems.find(
      (i) => i.product.id === productId
    );
    if (!cartItem)
      throw Error("Can't find cart item with productId of " + productId);

    cartItem.quantity = quantity;

    this.shoppingCartItems = [...this.shoppingCartItems];
    this.saveCartItems();
  }

  @action.bound
  public async addToShoppingCart(
    addedCallback: () => void,
    product: ICatalogProduct,
    option?: ICatalogOption
  ) {
    if (!option && product.options && product.options.length > 0) {
      throw Error("No product option was specified.");
    }

    const newTotalPoints = this.cartPoints + product.points;
    if (newTotalPoints > myStore.currentCompanyPointBalance!) {
      alert("You don't have enough points to purchase this item");
      return;
    }

    const existingItem = this.shoppingCartItems.find(
      (i) => i.product.id === product.id
    );

    if (existingItem) {
      existingItem.quantity += 1;
      this.shoppingCartItems = [...this.shoppingCartItems];
    } else {
      this.shoppingCartItems = [
        ...this.shoppingCartItems,
        { option, product, quantity: 1 },
      ];
    }

    await this.saveCartItems();
    addedCallback();
  }

  @action.bound
  public async checkForOutOfStockItems() {
    const items = this.shoppingCartItems.map<IOrderItem>((i) => ({
      productId: i.product.id,
      quantity: i.quantity,
      optionId: i.option ? i.option.id : undefined,
    }));

    if (items.length > 0) {
      this.isCheckingForOutOfStockItems = true;
      const apiResponse = await api.members.orders.verify(items);
      this.deletedOptionIds = apiResponse.data.deletedOptionIds;
      this.deletedProductIds = apiResponse.data.deletedProductIds;
      this.isCheckingForOutOfStockItems = false;
    } else {
      this.deletedProductIds = [];
      this.deletedOptionIds = [];
    }
  }

  @action.bound
  public async initializeStore() {
    const cartStoreJson = localStorage.getItem("cartStore");
    const shouldImportJson = cartStoreJson !== null;

    if (shouldImportJson) {
      // Not sure why the ! is needed here, but it is.
      const items = await this.saveCartItemsFromJSON(cartStoreJson!);
      this.shoppingCartItems = items;
      localStorage.removeItem("cartStore");
    } else {
      const items = await api.members.orders.getCartItems();
      this.shoppingCartItems = items;
    }
  }

  @action.bound
  private async saveCartItems() {
    // This used to be in a subscription on the shoppingCartItems array, but
    // was causing too many problems when impersonating.
    api.members.orders.saveCartItems(
      this.shoppingCartItems.map((i) => ({
        productId: i.product.id,
        quantity: i.quantity,
        optionId: i.option ? i.option.id : undefined,
      }))
    );
  }

  @action.bound
  private async saveCartItemsFromJSON(cartStoreJson: string) {
    const cartStore = JSON.parse(cartStoreJson) as LegacyLocalStorageFormat;
    if (cartStore.shoppingCartItems?.length > 0) {
      const items = await api.members.orders.saveCartItems(
        cartStore.shoppingCartItems.map((i) => {
          return {
            productId: i.product.id,
            optionId: i.option ? i.option.id : undefined,
            quantity: i.quantity,
          };
        })
      );

      return items;
    }

    return [];
  }

  @action.bound
  public async validateCatalogOrder() {
    this.showShipToAddressError =
      this.addressType === "existing" && !this.shipToAddressId;
    this.showAddress1Error = this.addressType === "new" && !this.address1;
    this.showFirstNameError = this.addressType === "new" && !this.firstName;
    this.showCityError = this.addressType === "new" && !this.city;
    this.showStateError = this.addressType === "new" && !this.state;
    this.showZipError = this.addressType === "new" && !this.zip;

    if (!this.phoneNumber) {
      this.phoneNumberError = "Please enter a phone number";
    } else if (this.phoneNumber.length !== 12) {
      // The input mask control we use includes underscores so the
      // length is always 12...but just in case
      this.phoneNumberError = "Please enter a valid phone number";
    } else if (this.phoneNumber.includes("_")) {
      this.phoneNumberError = "Please enter a valid phone number";
    } else {
      this.phoneNumberError = "";
    }

    if (
      this.showShipToAddressError ||
      this.showAddress1Error ||
      this.showFirstNameError ||
      this.showCityError ||
      this.showStateError ||
      this.showZipError ||
      this.deletedOptionIds.length > 0 ||
      this.deletedProductIds.length > 0 ||
      this.phoneNumberError.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  @action.bound
  public async createCatalogOrder(): Promise<CreateOrderResult> {
    if (this.shoppingCartItems.length === 0)
      throw Error("Can't create order. There's nothing in the shopping cart");

    this.isCreatingOrder = true;
    let created = false;

    const items = this.shoppingCartItems.map<IOrderItem>((i) => ({
      productId: i.product.id,
      quantity: i.quantity,
      optionId: i.option ? i.option.id : undefined,
    }));

    const request: ICreateOrderCommand = {
      items,
      shipToPhoneNumber: this.phoneNumber,
      type: "Catalog",
      shipToLocationId:
        this.addressType === "new" ? undefined : this.shipToAddressId,
      shipToAddress:
        this.addressType === "existing"
          ? undefined
          : {
              firstName: this.firstName,
              lastName: this.lastName,
              address1: this.address1,
              address2: this.address2,
              city: this.city,
              state: this.state,
              zip: this.zip,
            },
    };

    try {
      const result = await api.members.orders.create(request);

      if (result.data.poBoxAddressDetected) {
        this.isCreatingOrder = false;
        return "FailedPoBoxAddressDetected";
      }

      myStore.updatePointBalance(result.data.newPointBalance!);
      this.shoppingCartItems = [];
      this.addressType = "existing";
      this.firstName = "";
      this.lastName = "";
      this.address1 = "";
      this.address2 = "";
      this.city = "";
      this.state = "";
      this.zip = "";
      this.shipToAddressId = "";
      created = true;
      await this.saveCartItems();
    } catch (e) {
      console.error(e);
    }

    this.isCreatingOrder = false;
    return created ? "Succeeded" : "Failed";
  }
}

const cartStore = new CartStore();
export default cartStore;
