import { apiClient } from "services/api/client";
import { getFileName } from "services/api/modules/helpers";
import {
  INewEventData,
  IEventDetails,
  IListedAdminEvent,
  IListedCompanyInEvent,
  IExistingEventData,
  IAddAttachmentCommand,
  IEventAttachment,
  ICompanyEventDetails2,
  IEventAttendeeViewModel,
  IAddEventAdjustmentCommand,
  ICompanyEventAttendees,
  IEventAdjustment,
  IUpdateEventAdjustmentCommand,
  IAddEventPayerAdjustment,
  IEventPayerAdjustment,
  IUpdateEventGroupAdjustmentCommand,
  EventFilterType,
  IAddEventPayerPaymentCommand,
  IEventPayerPayment,
  IaccountEventSummary,
  DeleteAttendeeOption,
  ITinyEventPayerInvoice,
  ICompanyEvent,
  IUpdateEventAttendeesCommand,
  IUpdateEventGroupPaymentCommand,
  ISetPotentialEventGroupStatusCommand,
  IAddEventImageCommand,
  IEventImage,
  IAddCompanyEventNote,
  IClearPotentialEventGroupStatusCommand,
  IEventCounts,
  ICallSheetQuery,
  ICallSheetResult,
  IEventGroupConfirmationEmail,
  EventGroupExportType,
  IEventGroupRefundInfo,
  ICompanyEventNote,
  EventGroupStatus,
  ILargeEventPayerInvoice,
  EventPayerInvoiceType,
  IUpdatedEventAdjustmentResult,
  IStatementOfAccount,
} from "services/api/types";

const EVENTS_URL = "administrator/events";
const EVENT_GROUPS_URL = "administrator/eventGroups";
const EVENT_PAYERS_URL = "administrator/eventPayers";
const EVENT_PAYER_PAYMENTS_URL = "administrator/eventPayerPayments";
const EVENT_PAYER_INVOICES_URL = "administrator/eventPayerInvoices";

export const create = async (payload: INewEventData) => {
  const response = await apiClient.post<IEventDetails>(EVENTS_URL, payload);
  return response.data;
};

export const getEventInvoices = async (
  eventId: string,
  type: EventPayerInvoiceType
) => {
  const url = `${EVENTS_URL}/${eventId}/invoices/${type}`;
  const response = await apiClient.get<ILargeEventPayerInvoice[]>(url);
  return response.data;
};

export const signUpCompany = async (companyId: string, eventId: string) => {
  const url = `${EVENTS_URL}/${eventId}/signedUpCompanies`;
  const response = await apiClient.post<IListedCompanyInEvent>(url, {
    companyId,
    eventId,
  });

  return response;
};

export const deleteEventGroup = async (
  eventId: string,
  eventGroupId: string
) => {
  const url = `${EVENTS_URL}/${eventId}/eventGroups/${eventGroupId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const deleteEventPayerAdjustment = async (args: {
  eventPayerId: string;
  eventPayerAdjustmentId: string;
}) => {
  const url = `${EVENT_PAYERS_URL}/${args.eventPayerId}/adjustments/${args.eventPayerAdjustmentId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const setCancellationFee = async (args: {
  eventPayerId: string;
  cancellationFee: number;
}) => {
  const url = `${EVENT_PAYERS_URL}/${args.eventPayerId}/cancellationFee`;
  const response = await apiClient.post<IaccountEventSummary>(url, args);
  return response.data;
};

export const getEventGroupRefundInfo = async (eventGroupId: string) => {
  const url = `${EVENT_GROUPS_URL}/${eventGroupId}/refundInfo`;
  const response = await apiClient.get<IEventGroupRefundInfo>(url);
  return response.data;
};

export const getEventList = async (eventType: EventFilterType) => {
  const response = await apiClient.get<IListedAdminEvent[]>(EVENTS_URL, {
    params: {
      eventType,
    },
  });
  return response.data;
};

export const getStatementOfAccountJson = async (args: {
  eventId: string;
  accountId: string;
}) => {
  const url = `administrator/accounts/${args.accountId}/events/${args.eventId}/statementOfAccount`;
  const response = await apiClient.get<IStatementOfAccount>(url);
  return response.data;
};

export const getCompanyEventsSummary = async (companyId: string) => {
  // Shows information about a company's involvement in all
  // upcoming events that they could attend
  const url = `administrator/companies/${companyId}/events/summary`;
  const response = await apiClient.get<ICompanyEvent[]>(url);
  return response.data;
};

export const getEventAttendeesForCompany = async (
  companyId: string,
  eventId: string
) => {
  const url = `administrator/companies/${companyId}/events/${eventId}/attendees`;
  const response = await apiClient.get<ICompanyEventAttendees>(url);
  return response.data;
};

export const updateEventAttendees = async (
  command: IUpdateEventAttendeesCommand
) => {
  const url = `administrator/companies/${command.companyId}/events/${command.eventId}/attendees`;
  const response = await apiClient.put<ICompanyEventAttendees>(url, command);
  return response.data;
};

export const getAccountEventDetails = async (
  eventId: string,
  accountId: string
) => {
  const url = `administrator/accounts/${accountId}/events/${eventId}`;
  const response = await apiClient.get<ICompanyEventDetails2>(url);
  return response.data;
};

export const getCompanyEventDetails = async (
  eventId: string,
  companyId: string
) => {
  const url = `administrator/companies/${companyId}/events/${eventId}`;
  const response = await apiClient.get<ICompanyEventDetails2>(url);
  return response.data;
};

export const getEventDetails = async (eventId: string) => {
  const url = `${EVENTS_URL}/${eventId}`;
  const response = await apiClient.get<IEventDetails>(url);
  return response.data;
};

export const update = async (eventData: IExistingEventData) => {
  const url = `${EVENTS_URL}/${eventData.id}`;
  const response = await apiClient.put<IEventDetails>(url, eventData);
  return response.data;
};

export const addAttachment = async (command: IAddAttachmentCommand) => {
  const url = `${EVENTS_URL}/${command.eventId}/attachments`;
  const response = await apiClient.post<IEventAttachment>(url, command);
  return response.data;
};

export const addImage = async (command: IAddEventImageCommand) => {
  const url = `${EVENTS_URL}/${command.eventId}/images`;
  const response = await apiClient.post<IEventImage>(url, command);
  return response.data;
};

export const removeAttachment = async (
  eventId: string,
  attachmentId: string
) => {
  const url = `${EVENTS_URL}/${eventId}/attachments/${attachmentId}`;
  await apiClient.delete(url);
};

export const removeImage = async (eventId: string, eventImageId: string) => {
  const url = `${EVENTS_URL}/${eventId}/images/${eventImageId}`;
  await apiClient.delete(url);
};

export const getEventCounts = async (eventId: string) => {
  const url = `${EVENTS_URL}/${eventId}/counts`;
  const response = await apiClient.get<IEventCounts>(url);
  return response.data;
};

export const getEventGroups = async (
  eventId: string,
  statuses: EventGroupStatus[]
) => {
  const query = statuses.map((s) => `statuses=${s}`).join("&");
  const url = `${EVENTS_URL}/${eventId}/eventGroups?${query}`;
  const response = await apiClient.get<IListedCompanyInEvent[]>(url);
  return response.data;
};

export const getCallSheet = async (args: ICallSheetQuery) => {
  const url = `${EVENTS_URL}/${args.eventId}/callsheet/page`;
  const response = await apiClient.get<ICallSheetResult>(url, {
    params: args,
  });
  return response.data;
};

export const setPotentialEventGroupStatus = async (
  command: ISetPotentialEventGroupStatusCommand
) => {
  const { companyId, eventId } = command;
  const url = `${EVENTS_URL}/${eventId}/companies/${companyId}/status`;
  const response = await apiClient.post<IListedCompanyInEvent>(url, command);

  return response;
};

export const clearPotentialEventGroupStatus = async (
  command: IClearPotentialEventGroupStatusCommand
) => {
  const { companyId, eventId } = command;
  const url = `${EVENTS_URL}/${eventId}/companies/${companyId}/status`;
  const response = await apiClient.delete(url);
  return response;
};

export const addCompanyEventNote = async (command: IAddCompanyEventNote) => {
  const { companyId, eventId } = command;
  const url = `${EVENTS_URL}/${eventId}/companies/${companyId}/notes`;
  const response = await apiClient.post<ICompanyEventNote>(url, command);
  return response;
};

export const getAccountEventSummary = async (args: {
  accountId: string;
  eventId: string;
}) => {
  const url = `administrator/accounts/${args.accountId}/events/${args.eventId}/summary`;
  const response = await apiClient.get<IaccountEventSummary>(url);
  return response.data;
};

export const setGroupLead = async (
  eventGroupId: string,
  eventAttendeeId: string
) => {
  const url = `${EVENT_GROUPS_URL}/${eventGroupId}/groupLead`;

  const response = await apiClient.post(url, {
    eventGroupId,
    eventAttendeeId,
  });

  return response.data;
};

export const addEventAdjustment = async (
  command: IAddEventAdjustmentCommand
) => {
  const url = `${EVENTS_URL}/${command.eventId}/adjustments`;
  const response = await apiClient.post<IEventAdjustment>(url, command);
  return response.data;
};

export const getEventAdjustments = async (eventId: string) => {
  const url = `${EVENTS_URL}/${eventId}/adjustments`;
  const response = await apiClient.get<IEventAdjustment[]>(url);
  return response.data;
};

export const updateEventAdjustment = async (
  command: IUpdateEventAdjustmentCommand
) => {
  const url = `${EVENTS_URL}/${command.eventId}/adjustments/${command.eventAdjustmentId}`;
  const response = await apiClient.put<IUpdatedEventAdjustmentResult>(
    url,
    command
  );
  return response.data;
};

export const deleteEventAdjustment = async (
  eventId: string,
  eventAdjustmentId: string
) => {
  const url = `${EVENTS_URL}/${eventId}/adjustments/${eventAdjustmentId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const deleteEventGroupAttendee = async (
  eventGroupId: string,
  eventAttendeeId: string,
  option: DeleteAttendeeOption
) => {
  const url = `${EVENT_GROUPS_URL}/${eventGroupId}/attendees/${eventAttendeeId}`;
  const response = await apiClient.delete(url, {
    params: {
      deleteOption: option,
    },
  });
  return response;
};

// TODO - Might want to move these to an event payers "module"
export const addEventPayerAdjustment = async (
  command: IAddEventPayerAdjustment
) => {
  const url = `${EVENT_PAYERS_URL}/${command.eventPayerId}/adjustments`;
  const response = await apiClient.post<IEventPayerAdjustment>(url, command);
  return response.data;
};

export const addPayment = async (command: IAddEventPayerPaymentCommand) => {
  const url = `${EVENT_PAYERS_URL}/${command.payment.eventPayerId}/payments`;
  const response = await apiClient.post<IEventPayerPayment>(url, command);
  return response.data;
};

export const updateEventGroupAdjustment = async (
  command: IUpdateEventGroupAdjustmentCommand
) => {
  const url = `administrator/eventPayerAdjustments/${command.eventPayerAdjustmentId}`;
  const response = await apiClient.put<IEventPayerAdjustment>(url, command);
  return response.data;
};

/// END TODO

export const updateAttendeeChargeStatus = async (
  eventAttendeeId: string,
  isNoCharge: boolean
) => {
  const url = `administrator/eventAttendees/${eventAttendeeId}`;
  const response = await apiClient.put<IEventAttendeeViewModel>(url, {
    eventAttendeeId,
    isNoCharge,
  });

  return response.data;
};

export const deleteEvent = async (eventId: string) => {
  const url = `${EVENTS_URL}/${eventId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const deletePayment = async (args: {
  eventPayerId: string;
  paymentId: string;
}) => {
  const url = `${EVENT_PAYERS_URL}/${args.eventPayerId}/payments/${args.paymentId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const updatePayment = async (
  command: IUpdateEventGroupPaymentCommand
) => {
  const url = `${EVENT_PAYER_PAYMENTS_URL}/${command.payment.id}`;
  const response = await apiClient.put<IEventPayerPayment>(url, command);
  return response.data;
};

export const getCostPerPoint = async () => {
  const url = `${EVENTS_URL}/costPerPoint`;
  const response = await apiClient.get<{ eventCostPerPoint: number }>(url);
  return response.data.eventCostPerPoint;
};

export const addEventPayerInvoice = async (args: {
  eventPayerId: string;
  message: string;
  amount: number;
  dateDue: Date | undefined;
}) => {
  const url = `${EVENT_PAYERS_URL}/${args.eventPayerId}/invoices`;
  const response = await apiClient.post<ITinyEventPayerInvoice>(url, args);
  return response.data;
};

export const updateEventGroupNotes = async (
  companyId: string,
  eventId: string,
  newNote: {
    content: string;
    isImportant: boolean;
  },
  existingNoteIds: string[]
) => {
  const url = `administrator/companies/${companyId}/events/${eventId}/notes`;
  const response = await apiClient.put(url, {
    companyId,
    eventId,
    newNote,
    existingNoteIds,
  });

  return response;
};

export const updateEventGroupRoomsNeeded = async (
  eventGroupId: string,
  roomsNeeded?: number
) => {
  const url = `${EVENT_GROUPS_URL}/${eventGroupId}/roomsNeeded`;
  const response = await apiClient.put(url, {
    eventGroupId,
    roomsNeeded,
  });
  return response;
};

export const refundEventGroup = async (args: {
  eventGroupId: string;
  issueRefund: boolean;
  moveToWaitingList: boolean;
}) => {
  const url = `${EVENT_GROUPS_URL}/${args.eventGroupId}/refund`;
  const response = await apiClient.put(url, args);

  return response;
};

export const restoreEventGroup = async (eventGroupId: string) => {
  const url = `${EVENT_GROUPS_URL}/${eventGroupId}/restore`;
  const response = await apiClient.put(url, {
    eventGroupId,
  });

  return response;
};

export const voidInvoice = async (invoiceId: string) => {
  const url = `${EVENT_PAYER_INVOICES_URL}/${invoiceId}/void`;
  const response = await apiClient.post(url);
  return response;
};

export const unvoidInvoice = async (invoiceId: string) => {
  const url = `${EVENT_PAYER_INVOICES_URL}/${invoiceId}/unvoid`;
  const response = await apiClient.post(url);
  return response;
};

export const exportEventGroups = async (
  eventId: string,
  exportType: EventGroupExportType
) => {
  const event = await getEventDetails(eventId);

  const url = `${EVENTS_URL}/${eventId}/eventGroups/export/${exportType}`;
  const response = await apiClient.get(url, { responseType: "blob" });
  const fileName = getFileName(response, `Event Groups for ${event.name}.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportOutStandingEventBalanceReport = async () => {
  const url = `${EVENT_PAYERS_URL}/outstandingEventBalanceReport/export`;
  const response = await apiClient.get(url, { responseType: "blob" });
  const fileName = getFileName(response, `Event Groups.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const downloadEventAttendees = async (eventId: string) => {
  const event = await getEventDetails(eventId);

  const url = `${EVENTS_URL}/${eventId}/eventAttendees/export`;
  const response = await apiClient.get(url, { responseType: "blob" });
  const fileName = getFileName(
    response,
    `Event Attendees for ${event.name}.xlsx`
  );

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const sendEventGroupConfirmation = async (
  eventGroupId: string,
  contactId: string
) => {
  const url = `${EVENT_GROUPS_URL}/${eventGroupId}/confirmations`;
  const response = await apiClient.post<IEventGroupConfirmationEmail>(url, {
    contactId,
    eventGroupId,
  });

  return response.data;
};

export const sendTESTEventGroupConfirmation = async (eventId: string) => {
  const url = `${EVENTS_URL}/${eventId}/testConfirmationEmail`;
  const response = await apiClient.post<IEventGroupConfirmationEmail>(url);
  return response.data;
};

export const createEventBundle = async (args: {
  eventId: string;
  memberId?: string;
}) => {
  const url = `administrator/bundles`;
  const response = await apiClient.post(url, args);
  return response;
};
