import React from "react";
import { LookupListType } from "services/api/types";
import Alert from "@material-ui/lab/Alert";

type Props = {
  listType: LookupListType;
};

const ListTypeNotes = (props: Props) => {
  if (props.listType === "EventTypes") {
    return (
      <Alert severity="info">
        The imageFileName field is used as a backup when no event images exist.
      </Alert>
    );
  }

  if (props.listType === "Markets") {
    return (
      <Alert severity="info">
        Markets with isActive set to false will not appear in the dropdown list
        when adding a new company.
      </Alert>
    );
  }

  if (props.listType === "RelationshipTypes") {
    return (
      <Alert severity="warning">
        <div>
          The oppositeTypeId field needs to be the value of any existing
          relationship type (including its own Id for types where the "opposite"
          is itself (e.g. Friend, Spouse)).
        </div>
        <br />
        <div>
          If you create a new type, you may have to populate this field with the
          Id of a type that doesn't make sense temporarily. Once you save it,
          the new type will have an Id. Then you can edit it and enter the new
          Id in the oppositeTypeId field.
        </div>
      </Alert>
    );
  }

  return <div />;
};

export default ListTypeNotes;
