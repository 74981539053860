import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ICatalogProduct, ICatalogOption } from "services/api/types";
import ProductOptions from "./ProductOptions";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { formatNumber } from "utils/number";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { getPointsTermProper } from "utils/appText";

type Props = {
  handleClose: () => void;
  product: ICatalogProduct;
  onAddToCart: (product: ICatalogProduct, option?: ICatalogOption) => void;
} & RouteComponentProps;

const MobileProduct = (props: Props) => {
  const [selectedOptionId, setSelectedOptionId] = React.useState<string>();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr",
        marginBottom: theme.spacing(2),
      },
      img: {
        margin: "0 auto",
      },
      details: {
        padding: theme.spacing(2),
      },
      productName: {
        fontWeight: "bold",
        fontSize: 14,
        color: theme.palette.grey[800],
      },
      modelNumber: {
        color: theme.palette.grey[500],
      },
      salesCopy: {
        marginTop: theme.spacing(2),
        color: theme.palette.grey[700],
      },
      cost: {
        color: theme.palette.grey[700],
        fontSize: 16,
        fontWeight: "bold",
      },
      costHost: {
        textAlign: "center",
        padding: theme.spacing(2, 0),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        borderColor: "gainsboro",
        borderWidth: "1px 0 1px 0",
        borderStyle: "solid",
        marginTop: theme.spacing(2),
      },
      costCaption: {
        color: theme.palette.grey[700],
        fontSize: "smaller",
        textTransform: "uppercase",
        marginBottom: 0,
        display: "block",
      },
      brand: {
        cursor: "pointer",
        color: theme.palette.grey[500],
      },
      categoryPath: {
        display: "inline-flex",
        color: theme.palette.secondary.light,
        fontSize: "10pt",
        "& > :first-child": {
          marginLeft: 0,
        },
      },
      category: {
        margin: "0 .5em",
        cursor: "pointer",
      },
      adminInfo: {
        color: "blue",
      },
      addToCartButton: {},
      viewDetailsButton: {},
      productDetails: {
        paddingBottom: theme.spacing(2),
      },
      clubPoints: {
        display: "inline-block",
        marginRight: theme.spacing(3),
      },
    })
  );

  const renderImage = () => {
    if (selectedOptionId) {
      const option = props.product.options.find(
        (o) => o.id === selectedOptionId
      );
      if (!option)
        throw Error(`Could not find option with id ${selectedOptionId}`);
      return (
        <img className={classes.img} src={option.image} alt={option.size} />
      );
    } else {
      return (
        <img
          className={classes.img}
          src={props.product.imageUrl}
          alt={props.product.productName}
        />
      );
    }
  };

  const classes = useStyles();
  const { product } = props;

  React.useEffect(() => {
    return () => {
      setSelectedOptionId(undefined);
    };
  }, []);

  const renderAddToCartButton = () => {
    return (
      <Button
        className={classes.addToCartButton}
        disabled={product.points === undefined}
        onClick={() => {
          if (product.options.length > 0 && !selectedOptionId) {
            alert("Please select an option");
            const dropdown = document.getElementById(
              `${props.product.id}+options`
            );
            if (dropdown) {
              dropdown.focus();
            }
            return;
          }
          const option = props.product.options.find(
            (o) => o.id === selectedOptionId
          );

          props.onAddToCart(props.product, option);
        }}
        variant="contained"
        color="secondary"
      >
        Add to Cart
      </Button>
    );
  };

  return (
    <ResponsiveModalShell
      title="Product Details"
      handleClose={props.handleClose}
    >
      <div className={classes.root}>
        {renderImage()}
        <div className={classes.productDetails}>
          <Typography className={classes.brand} variant="caption">
            {product.brand}
          </Typography>
          <Typography className={classes.productName} variant="h5">
            {product.productName}
          </Typography>
          <div className={classes.modelNumber}>Model: {product.model}</div>
          <ProductOptions
            productId={props.product.id}
            selectedOptionId={selectedOptionId}
            setSelectedOptionId={setSelectedOptionId}
            options={product.options}
          />
          <div className={classes.costHost}>
            <span className={classes.clubPoints}>
              <Typography className={classes.cost} variant="h5">
                {product.points ? formatNumber(product.points) : "???"}
              </Typography>
              <Typography className={classes.costCaption}>
                {getPointsTermProper()}
              </Typography>
            </span>
            {renderAddToCartButton()}
          </div>
          <div className={classes.salesCopy}>{product.salesCopy}</div>
        </div>
      </div>
    </ResponsiveModalShell>
  );
};

export default withRouter(MobileProduct);
