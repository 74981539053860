import { observer } from "mobx-react";
import React, { Component, ComponentClass, ComponentProps } from "react";
import { Omit } from "utils/type";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import AddCompanyBrand from "modals/containers/CompanyModals/AddBrand";
import AddSupplier from "modals/containers/CompanyModals/AddSupplier";
import UpdateSupplier from "modals/containers/CompanyModals/UpdateSupplier";
import AddCompanyContact from "modals/containers/CompanyModals/AddContact";
import AddPurchaserSupplier from "modals/containers/CompanyModals/AddPurchaserSupplier";
import AddSupplierPurchaser from "modals/containers/CompanyModals/AddSupplierPurchaser";
import AddCompanyLocation from "modals/containers/CompanyModals/AddLocation";
import AddCompanyCategory from "modals/containers/CompanyModals/AddCategory";
import AddCompanyNote from "modals/containers/CompanyModals/AddNote";
import GenericAddNote from "modals/containers/CompanyModals/GenericAddNote";
import AddCompanyProduct from "modals/containers/CompanyModals/AddProduct";
import UpdateMember from "modals/containers/CompanyModals/UpdateMember";
import UpdateCompanyLocation from "modals/containers/CompanyModals/UpdateLocation";
import UpdateCompanyCategory from "modals/containers/CompanyModals/UpdateCategory";
import UpdateCompanyNote from "modals/containers/CompanyModals/UpdateNote";
import AddEditTransactionModal from "areas/Admin/Transactions/AddEditTransactionModal";
import AddEditMonthlyReportPaymentModal from "areas/Admin/MonthlyReports/AddEditMonthlyReportPaymentModal";
import AddEditEventPayerPaymentModal from "areas/Admin/EventGroups/modals/AddEditEventPayerPaymentModal";
import AddEditAttachment from "areas/Admin/Postings/AddEditAttachmentModal";
import CreateMonthlyReportModal from "areas/Shared/MonthlyReports/modals/CreateMonthlyReportModal";
import ViewCompanyEventAttendees from "areas/Shared/Events/components/ViewCompanyEventAttendees";
import ConfirmCancelOrderModal from "modals/containers/ConfirmCancelOrderModal";
import ChangePasswordModal from "modals/containers/ChangePasswordModal";
import AddEditEventModal from "areas/Admin/Events/modals/AddEditEventModal";
import AddEventAttachmentModal from "areas/Admin/Events/modals/AddEventAttachmentModal";
import AddEventImageModal from "areas/Admin/Events/modals/AddEventImageModal";
import AddEditEventAdjustmentModal from "areas/Admin/Events/modals/AddEditEventAdjustmentModal";
import AddEditCancellationFee from "areas/Admin/Events/modals/AddEditCancellationFee";
import AddEditEventPayerAdjustmentModal from "areas/Admin/EventGroups/modals/AddEditEventPayerAdjustmentModal";
import CreateEventGroupModal from "areas/Admin/EventGroups/modals/CreateEventGroupModal";
import ConfirmRemoveAttendeeModal from "areas/Admin/Events/modals/ConfirmRemoveAttendeeModal";
import CreateInvoiceModal from "areas/Admin/Events/modals/CreateInvoiceModal";
import AddEditUserModal from "areas/Admin/Users/AddEditUserModal";
import AddEditNewspaperModal from "areas/Admin/Newspapers/AddEditNewspaperModal";
import UpdateEventGroupInvoicesModal from "areas/Admin/EventGroups/modals/UpdateEventGroupInvoicesModal";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import uiStore from "stores/ui";
import MobileProduct from "areas/Members/Catalog/components/MobileProduct";
import MemberInfoDialogWrapper from "areas/Sponsors/MemberDirectory/MemberInfoDialogWrapper";
import AddUpdateContactPhotoModal from "areas/Shared/Contacts/modals/AddUpdateContactPhotoModal";
import CancellationPolicyModal from "areas/Admin/Events/modals/CancellationPolicyModal";
import ConfirmRefundEventGroupModal from "areas/Admin/EventGroups/modals/ConfirmRefundEventGroupModal";
import SendNewMemberWelcomeEmailModal from "areas/Admin/Email/SendNewMemberWelcomeEmailModal";
import SendNewSponsorReferralConfirmationModal from "areas/Admin/Email/SendNewSponsorReferralConfirmationModal";
import CreateMemberReferralModal from "areas/Sponsors/CreateMemberReferralModal";
import GenericFormModal from "areas/Admin/modals/GenericFormModal";
import AddTrackedLeadModal from "areas/Admin/TrackedLeads/AddTrackedLeadModal";
import NewMemberUploadModal from "areas/Shared/CompanyDetails/modals/NewMemberUploadModal";
import SendMemberUploadEmailsModal from "areas/Shared/CompanyDetails/modals/SendMemberUploadEmailsModal";
import MergeCompanyModal from "areas/Admin/modals/MergeCompanyModal";
import CheckedContactsModal from "areas/Admin/modals/CheckedContactsModal";
import ReplinkInfoModal from "areas/Admin/modals/ReplinkInfoModal";
import HostCompanyInfoModal from "areas/Admin/modals/HostCompanyInfoModal";
import MergeContactsModal from "areas/Admin/modals/MergeContactsModal";
import CloseMemberAccountModal from "areas/Admin/modals/CloseMemberAccountModal";
import CompanyExportOptionsModal from "areas/Admin/Reports/Modals/CompanyExportOptionsModal";

export const modalComponents = {
  AddSupplier,
  NewMemberUploadModal,
  UpdateSupplier,
  UpdateMember,
  AddCompanyLocation,
  UpdateCompanyLocation,
  AddCompanyCategory,
  UpdateCompanyCategory,
  AddCompanyProduct,
  AddCompanyBrand,
  AddCompanyNote,
  UpdateCompanyNote,
  AddCompanyContact,
  AddEditEventPayerPaymentModal,
  AddEditMonthlyReportPaymentModal,
  AddEditAttachment,
  CreateMonthlyReportModal,
  ConfirmCancelOrderModal,
  ChangePasswordModal,
  AddEditEventModal,
  AddEventAttachmentModal,
  AddEditEventAdjustmentModal,
  AddEditEventPayerAdjustmentModal,
  CreateEventGroupModal,
  ConfirmRemoveAttendeeModal,
  CreateInvoiceModal,
  AddEditUserModal,
  AddPurchaserSupplier,
  AddSupplierPurchaser,
  AddEditNewspaperModal,
  AddEditTransactionModal,
  UpdateEventGroupInvoicesModal,
  AddEventImageModal,
  MobileProduct,
  MemberInfoDialogWrapper,
  AddUpdateContactPhotoModal,
  CancellationPolicyModal,
  ConfirmRefundEventGroupModal,
  GenericAddNote,
  SendNewMemberWelcomeEmailModal,
  SendNewSponsorReferralConfirmationModal,
  CreateMemberReferralModal,
  ViewCompanyEventAttendees,
  GenericFormModal,
  AddTrackedLeadModal,
  SendMemberUploadEmailsModal,
  AddEditCancellationFee,
  MergeCompanyModal,
  CheckedContactsModal,
  ReplinkInfoModal,
  HostCompanyInfoModal,
  MergeContactsModal,
  CloseMemberAccountModal,
  CompanyExportOptionsModal,
};

export type ModalType = keyof typeof modalComponents;

// We're using a custom type called Omit here not the built in one.
// This one causes an error when a modal doesn't not have the
// prop being Omitted (handleClose). The built-in one does not
export type ModalComponentProps<T extends ModalType> = Omit<
  ComponentProps<(typeof modalComponents)[T]>,
  "handleClose"
>;

export type GlobalModalProps = {
  width: Breakpoint;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

@observer
class GlobalModal extends Component<GlobalModalProps> {
  public componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  public render() {
    const ContentComponent =
      uiStore.modal &&
      (modalComponents[uiStore.modal.type] as ComponentClass<any>);
    const contentProps = uiStore.modal ? uiStore.modal.props : {};

    return (
      <Dialog
        fullScreen={this.props.width === "xs"}
        fullWidth={this.props.width === "xs"}
        maxWidth={uiStore.modelWidth}
        keepMounted={true}
        open={uiStore.isModalOpen}
        onClose={() => uiStore.closeModal()}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <>
            <div id="mobile-dialog-contents-portal" />
            {ContentComponent && (
              <ContentComponent
                handleClose={uiStore.closeModal}
                {...contentProps}
              />
            )}
          </>
        </DialogContent>
        <DialogActions id="mobile-dialog-actions-portal"></DialogActions>
      </Dialog>
    );
  }

  private handleKeyDown = (arg1: any) => {
    if (arg1.key === "Escape") {
      uiStore.closeModal();
    }
  };
}

export default withWidth()(GlobalModal);
