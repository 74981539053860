import { apiClient } from "services/api/client";
import { ICompanyInfo, ICompanyStats, IDataQualityStats } from "../../types";

const statisticsUrl = "administrator/statistics";

export const getCompanyStats = async (activeOnly: boolean) => {
  const url = `${statisticsUrl}/companies`;
  const response = await apiClient.get<ICompanyStats>(url, {
    params: {
      activeOnly,
    },
  });
  return response;
};

export const getDataQualityStats = async () => {
  const url = `${statisticsUrl}/dataQuality`;
  const response = await apiClient.get<IDataQualityStats>(url);
  return response;
};

export const getPrimaryContactWithoutEmailCompanies = async () => {
  const url = `${statisticsUrl}/dataQuality/noEmail`;
  const response = await apiClient.get<ICompanyInfo[]>(url);
  return response;
};

export const getCompaniesWithoutPrimaryCategory = async () => {
  const url = `${statisticsUrl}/dataQuality/noPrimaryCategory`;
  const response = await apiClient.get<ICompanyInfo[]>(url);
  return response;
};

export const getCompaniesWithoutPrimaryContact = async () => {
  const url = `${statisticsUrl}/dataQuality/noPrimaryContact`;
  const response = await apiClient.get<ICompanyInfo[]>(url);
  return response;
};

export const getCompaniesWithoutPrimaryLocation = async () => {
  const url = `${statisticsUrl}/dataQuality/noPrimaryLocation`;
  const response = await apiClient.get<ICompanyInfo[]>(url);
  return response;
};

export const getCompaniesWithNoActiveUserAccounts = async () => {
  const url = `${statisticsUrl}/dataQuality/noActiveUserAccounts`;
  const response = await apiClient.get<ICompanyInfo[]>(url);
  return response;
};

export const getCompaniesWithNoLoggedInUsers = async () => {
  const url = `${statisticsUrl}/dataQuality/noLoggedInUsers`;
  const response = await apiClient.get<ICompanyInfo[]>(url);
  return response;
};
