import React from "react";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import CompanyDetails from "areas/Shared/CompanyDetails/CompanyDetailsContainer";
import cartStore from "stores/cart";
import { paths } from "./Paths";
import SubtleLinearProgress from "components/Shell/SubtleLinearProgress";

const ProfileSubmittedMessage = React.lazy(
  () => import("areas/Members/SupplierProfile/ProfileSubmittedMessage")
);

const MemberLandingPage = React.lazy(
  () => import("areas/Members/Dashboard/MemberLandingPage")
);
const UpcomingEvents = React.lazy(() => import("areas/Shared/Events/Events"));
const EventAttendeesContainer = React.lazy(
  () => import("areas/Shared/Events/EventAttendeesContainer")
);
const EventDetails = React.lazy(
  () => import("areas/Shared/Events/EventDetails")
);
const PastEvents = React.lazy(
  () => import("areas/Shared/Events/PastEventsContainer")
);
const SupplierProfileContainer = React.lazy(
  () => import("areas/Members/SupplierProfile/SupplierProfileContainer")
);
const SponsorDirectoryContainer = React.lazy(
  () => import("areas/Shared/SponsorDirectory/SponsorDirectoryContainer")
);
const OrdersListContainer = React.lazy(
  () => import("areas/Shared/Orders/OrdersContainer")
);
const RoutedOrderDetailsContainer = React.lazy(
  () => import("areas/Shared/Orders/components/OrderDetailsContainer")
);
const PointsHistoryContainer = React.lazy(
  () => import("areas/Shared/PointsHistory/PointsHistoryContainer")
);
const DestinationContainer = React.lazy(
  () => import("areas/Members/Destinations/DestinationContainer")
);
const GiftCardsContainer = React.lazy(
  () => import("areas/Members/GiftCards/GiftCardsContainer")
);
const CatalogContainer = React.lazy(
  () => import("areas/Members/Catalog/CatalogContainer")
);
const CatalogHomeContainer = React.lazy(
  () => import("areas/Members/Catalog/CatalogHome")
);
const CartCheckout = React.lazy(
  () => import("areas/Members/Orders/CheckoutConatiner")
);
const OrderSubmitted = React.lazy(
  () => import("areas/Members/Orders/OrderSubmittedPage")
);
const OrderProblem = React.lazy(
  () => import("areas/Members/Orders/OrderProblemPage")
);
const RequestSubmittedPage = React.lazy(
  () => import("areas/Members/GiftCards/RequestSubmittedPage")
);
const NewspapersContainer = React.lazy(
  () => import("areas/Shared/Newspapers/NewspaperIssuesContainer")
);
const ContactUs = React.lazy(() => import("areas/Shared/ContactUs"));
const MembershipDetails = React.lazy(
  () => import("areas/Members/MembershipDetailsPage")
);
const MerchandiseInfo = React.lazy(
  () => import("areas/Members/MerchandiseInfo")
);

const MemberRoutes = () => {
  const history = useHistory();

  React.useEffect(() => {
    cartStore.initializeStore();
  }, []);

  return (
    <React.Suspense fallback={<SubtleLinearProgress />}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={paths.app.root}
            render={() => <Redirect to={`${paths.app.members.root}`} />}
          />
          <Route
            exact
            path={paths.app.members.root}
            component={MemberLandingPage}
          />
          <Route
            path={paths.app.members.myCompany}
            component={CompanyDetails}
          />
          <Route
            exact
            path={`${paths.app.members.root}/newspapers`}
            render={(p) => (
              <NewspapersContainer {...p} audience="CompanyUsers" />
            )}
          />
          <Route
            exact
            path={`${paths.app.members.root}/membership-details`}
            render={(p) => <MembershipDetails hasToolbar={true} />}
          />
          <Route
            exact
            path={`${paths.app.members.root}/merchandise-info`}
            render={(p) => <MerchandiseInfo />}
          />
          <Route
            exact
            component={SupplierProfileContainer}
            path={`${paths.app.members.root}/supplier-profile`}
          />
          <Route
            exact
            component={ProfileSubmittedMessage}
            path={`${paths.app.members.root}/supplier-profile/thank-you`}
          />
          <Route
            exact
            path={`${paths.app.members.root}/events`}
            render={(p) => <UpcomingEvents {...p} audience="Member" />}
          />
          <Route
            exact
            path={`${paths.app.members.root}/events/:eventId/attendees`}
            component={EventAttendeesContainer}
          />
          <Route
            exact
            path={`${paths.app.members.root}/events/:eventId/signup`}
            component={EventDetails}
          />
          <Route
            exact
            path={`${paths.app.members.root}/points/history`}
            render={(p) => (
              <PointsHistoryContainer
                {...p}
                mode="MemberPointsEarned"
                hasToolbar={true}
              />
            )}
          />
          <Route
            exact
            path={`${paths.app.members.root}/past-events`}
            component={PastEvents}
          />
          <Route
            path={`${paths.app.members.root}/sponsors`}
            render={() => <SponsorDirectoryContainer mode="Member" />}
          />
          <Route
            exact
            path={`${paths.app.members.root}/orders`}
            component={OrdersListContainer}
          />
          <Route
            exact
            path={`${paths.app.members.root}/orders/:friendlyOrderId`}
            component={RoutedOrderDetailsContainer}
          />
          <Route
            exact
            path={`${paths.app.members.root}/airfare`}
            render={() => <DestinationContainer type="airfare" />}
          />
          <Route
            exact
            path={`${paths.app.members.root}/hotel`}
            render={() => <DestinationContainer type="hotel" />}
          />
          <Route
            exact
            path={`${paths.app.members.root}/gift-card-orders/create`}
            component={GiftCardsContainer}
          />
          <Route
            path={`${paths.app.members.root}/gift-card-orders/:giftCardOrderId`}
            component={GiftCardsContainer}
          />
          <Route
            exact
            path={paths.app.adminsAndMembers.catalog}
            component={CatalogContainer}
          />
          <Route
            exact
            path={paths.app.adminsAndMembers.mobileCatalog}
            component={CatalogHomeContainer}
          />
          <Route
            exact
            path={`${paths.app.members.root}/checkout`}
            component={CartCheckout}
          />
          <Route
            exact
            path={`${paths.app.members.root}/order-submitted`}
            component={OrderSubmitted}
          />
          <Route
            exact
            path={`${paths.app.members.root}/order-problem`}
            component={OrderProblem}
          />
          <Route
            exact
            path={`${paths.app.members.root}/request-submitted`}
            component={RequestSubmittedPage}
          />
          <Route
            exact
            path={`${paths.app.members.root}/contact`}
            component={ContactUs}
          />
          <Route path="*" exact={true} render={() => <div>Not found</div>} />
        </Switch>
      </Router>
    </React.Suspense>
  );
};

export default MemberRoutes;
